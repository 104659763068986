export const InitialState = {
  basket: [],
  wallet: {
    address: 'YEET',
    balance: 'MOTHERFUCKER',
    connected: false,
  },
  supply: {
    left: '???',
    total: '???',
  },
  price: '???',
  allowedBurnAmount: 0,
  burnStatusMessage: '',
};

export const Reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_SUPPLY':
      return {
        ...state,
        supply: action.supply,
      };
    case 'UPDATE_WALLET':
      return {
        ...state,
        wallet: action.wallet,
      };
    case 'UPDATE_PRICE':
      return {
        ...state,
        price: action.price,
      };
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        message: action.message,
      };
    case 'BURN_STATE':
      return {
        ...state,
        allowedBurnAmount: action.allowedBurnAmount,
      };
    case 'BURN_MESSAGE':
      return {
        ...state,
        burnStatusMessage: action.burnStatusMessage,
      };

    case 'IS_CLAIM_OPEN':
      return {
        ...state,
        isClaimOpen: action.isClaimOpen,
      };

    default:
      return state;
  }
};

// eslint-disable-next-line max-len
export const getBasketTotal = (basket) => basket?.reduce((amount, item) => Math.round(item.price + amount), 0);
