import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import ChonkyNft from '../../ChonkyNft.json';
import './Wallet.css';
import ValidationError from '../../exception/ValidationError';
import { fetchProof } from '../../services/FirebaseService';
import { useStateValue } from '../../StateProvider/StateProvider';
import Message from '../Message/Message';

function ConnectWalletButton({ clickHandler }) {
  return (
    <button type="submit" className="connect-wallet-btn" onClick={clickHandler}>
      CONNECT
    </button>
  );
}

const SalesState = {
  CLOSED: 0,
  OPEN: 1,
  PRESALE: 2,
};

export default function Wallet() {
  const [contractInfo, setContractInfo] = useState({});
  const [walletInfo, setWalletInfo] = useState({});
  const [selectedAmount, setSelectedAmount] = useState(0);

  const [mintButtonDisabled, setMintButtonDisabled] = useState(false);

  const [haveMetamask, sethaveMetamask] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(true);

  const [, dispatch] = useStateValue();

  const updateSupply = () => {
    dispatch({
      type: 'UPDATE_SUPPLY',
      supply: {
        minted: contractInfo.mintedSupply,
        total: contractInfo.presaleSupply,
      },
    });
  };

  const updateWalletGlobalState = () => {
    dispatch({
      type: 'UPDATE_WALLET',
      wallet: {
        address: walletInfo.address,
        balance: walletInfo.balance,
      },
    });
  };

  const updatePrice = () => {
    dispatch({
      type: 'UPDATE_PRICE',
      price: contractInfo.presalePrice,
    });
  };

  const updateMessage = (displayedMessage) => {
    dispatch({
      type: 'UPDATE_MESSAGE',
      message: displayedMessage,
    });
  };

  const checkButtonDisabled = () => {
    setMintButtonDisabled(false);
    updateMessage('');
    if (contractInfo && contractInfo.salesState !== SalesState.PRESALE) {
      setMintButtonDisabled(true);
      updateMessage('Presale hasn\'t started yet');
      return;
    }

    if (walletInfo && walletInfo.proofExists === false) {
      setMintButtonDisabled(true);
      updateMessage('Unfortunately you cannot mint :/');
    }

    if (walletInfo.hasClaimedWl) {
      setMintButtonDisabled(true);
      updateMessage('you\'ve used your privileges already :P');
    }

    if (walletInfo.nftsInBalance >= contractInfo.presaleMaxPerWallet) {
      setMintButtonDisabled(true);
      updateMessage('You already have enough CHONKY BOIS');
    }
  };

  const CONTRACT_ADDRESS = process.env.REACT_APP_CONTRACT_ADDRESS;
  const CONTRACT_ABI = ChonkyNft.abi;

  const { ethereum } = window;

  useEffect(() => {
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const network = window.ethereum.networkVersion;
      if (network) {
        if (network !== '1') {
          setIsCorrectNetwork(false);
        } else {
          setIsCorrectNetwork(true);
        }
      }
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      updateSupply();
      updateWalletGlobalState();
      updatePrice();
      checkButtonDisabled();
    }
  }, [isLoading]);

  const initWalletInfo = async (accounts, provider, connectedContract) => {
    const address = accounts[0];
    const balance = await provider.getBalance(address);
    const nftsInBalance = await connectedContract.balanceOf(address, 0);
    const hasClaimedWl = await connectedContract.getWhitelistClaimed(
      0,
      address,
    );
    const proof = await fetchProof(address);

    Promise.all([balance, nftsInBalance, hasClaimedWl, proof]).then(
      setWalletInfo({
        ...walletInfo,
        address,
        balance: Number(ethers.utils.formatEther(balance)),
        nftsInBalance: nftsInBalance.toNumber(),
        proofExists: proof.val() !== undefined && proof.val() !== null,
        proof: proof.val(),
        hasClaimedWl,
      }),
    );
  };

  const initContractInfo = async (connectedContract) => {
    const mintedSupply = await connectedContract.getMintedSupply(0);
    const supply = await connectedContract.getSupply(0);
    const maxPerWallet = await connectedContract.getMaxPerWallet(0);
    const maxPerTransaction = await connectedContract.getMaxPerTransaction(0);
    const price = await connectedContract.getPrice(0);
    const presaleMaxPerWallet = await connectedContract.getPresaleMaxPerWallet(0);
    const presaleMaxPerTransaction = await connectedContract.getPresaleMaxPerTransaction(0);
    const presalePrice = await connectedContract.getPresalePrice(0);
    const presaleSupply = await connectedContract.getPresaleSupply(0);
    const salesState = await connectedContract.getState(0);
    const promises = [mintedSupply, supply, maxPerWallet, maxPerTransaction, price,
      presaleMaxPerWallet, presaleMaxPerTransaction, presalePrice, presaleSupply, salesState];

    Promise.all(promises).then(() => {
      setContractInfo({
        ...contractInfo,
        mintedSupply: mintedSupply.toNumber(),
        supply: supply.toNumber(),
        maxPerWallet: maxPerWallet.toNumber(),
        maxPerTransaction: maxPerTransaction.toNumber(),
        price: Number(ethers.utils.formatEther(price)),
        presaleMaxPerWallet: presaleMaxPerWallet.toNumber(),
        presaleMaxPerTransaction: presaleMaxPerTransaction.toNumber(),
        presalePrice: Number(ethers.utils.formatEther(presalePrice)),
        presaleSupply: presaleSupply.toNumber(),
        salesState,
      });
    });
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI,
        signer,
      );
      setIsLoading(true);
      await initContractInfo(connectedContract);
      await initWalletInfo(accounts, provider, connectedContract);
      setIsLoading(false);
      setIsConnected(true);
    } catch (error) {
      console.log(error);
      setIsConnected(false);
      updateMessage('Something went horribly wrong');
    }
  };

  const handleAccountsChanged = (...args) => {
    const accounts = args[0];
    if (accounts.length === 0) {
      sethaveMetamask(false);
    } else if (accounts[0] !== walletInfo.address) {
      window.location.reload();
    }
  };

  useEffect(() => {
    ethereum?.on('accountsChanged', handleAccountsChanged);
    return () => {
      updateWalletGlobalState();
      ethereum?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  const validate = () => {
    if (walletInfo.proofExists === false) {
      throw new ValidationError('Unfortunately you cannot mint :/');
    }

    if (selectedAmount < 1) {
      throw new ValidationError('WTF IS THIS NUMBER');
    }

    if (selectedAmount > contractInfo.presaleMaxPerTransaction) {
      throw new ValidationError(`You can only mint ${contractInfo.presaleMaxPerTransaction} CHONKY POPS per tx :P`);
    }

    if (
      Number(walletInfo.nftsInBalance) + Number(selectedAmount)
      > contractInfo.presaleMaxPerWallet
    ) {
      throw new ValidationError('You already have too much CHONKY POPS');
    }

    if (selectedAmount > contractInfo.presaleSupply - contractInfo.mintedSupply) {
      throw new ValidationError('There isn\'t that many left :/');
    }
  };

  const mint = async () => {
    try {
      if (ethereum) {
        validate();
        const provider = new ethers.providers.Web3Provider(ethereum, 'any');
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          CONTRACT_ABI,
          signer,
        );
        updateMessage('Confirm tx in Metamask plz');

        const gasEstimate = await connectedContract.estimateGas.presaleMint(
          0,
          selectedAmount,
          walletInfo.proof.split(','),
          { value: ethers.utils.parseEther(contractInfo.presalePrice.toString()) },
        );

        const txn = await connectedContract.presaleMint(
          0,
          selectedAmount,
          walletInfo.proof.split(','),
          { gasLimit: gasEstimate.toNumber() + 10000, value: ethers.utils.parseEther(contractInfo.presalePrice.toString()) },
        );
        updateMessage('Waiting for the tx to complete...');
        await txn.wait();
        updateMessage('Enjoy your CHONKY POPS');
        connectWallet(); // to reset state
      } else {
        console.log('Ethereum object does not exist');
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        updateMessage(error.message);
      } else {
        if (error.code === 'ACTION_REJECTED') {
          updateMessage('you rejected? :(');
        } else {
          updateMessage('Something went wrong... :/');
        }

        console.log(error);
      }
    }
  };

  const increment = () => {
    setSelectedAmount(selectedAmount + 1);
  };

  const decrement = () => {
    if (selectedAmount >= 1) {
      setSelectedAmount(selectedAmount - 1);
    }
  };

  if (!haveMetamask) {
    return <div style={{ padding: '16px' }}><Message text="PLZ INSTALL METAMASK :P" /></div>;
  }

  if (!isCorrectNetwork) {
    return <div style={{ padding: '16px' }}><Message text="PLZ SWITCH TO ETHEREUM MAINNET :P" /></div>;
  }

  return (
    <div className="wallet">
      {isConnected ? (
        <div className="minting">
          <div className="quantity">
            <button className="eightbit-btn" onClick={decrement} disabled={mintButtonDisabled}>-</button>
            <button className="amount-btn" disabled={mintButtonDisabled}>{selectedAmount}</button>
            <button className="eightbit-btn" onClick={increment} disabled={mintButtonDisabled}>+</button>
          </div>
          <button type="submit" className="eightbit-btn" onClick={mint} disabled={mintButtonDisabled}>Mint</button>
        </div>
      ) : (
        <ConnectWalletButton clickHandler={connectWallet} />
      )}
    </div>
  );
}
