import React, { createContext, useReducer, useContext } from 'react';

// empty data layer
export const StateContext = createContext();

// provider
export function StateProvider({ reducer, initialState, children }) {
  return (
    <StateContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </StateContext.Provider>
  );
}

// store
export const useStateValue = () => useContext(StateContext);
