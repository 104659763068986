import React from 'react';
import './Store.css';
import Description from '../../components/Description/Description';
import Wallet from '../../components/Wallet/Wallet';
import WalletDetails from '../../components/WalletDetails/WalletDetails';
import Message from '../../components/Message/Message';
import Price from '../../components/Price/Price';
import ImageContainer from '../../components/ImageContainer/ImageContainer';
import { useStateValue } from '../../StateProvider/StateProvider';
import Footer from '../../components/Footer/Footer';
import home from '../../static/home.png';
import fire from '../../static/fire3.png';
import instructions from '../../static/lego3_small.png';

export default function Store() {
  const [{
    supply, wallet, price, message,
  }] = useStateValue();

  const GetNrMinted = () => {
    if (supply.minted) {
      return supply.minted;
    }
    if (supply.minted === 0) {
      return 0;
    }
    return '???';
  };
  return (
    <div className="container">
      <div className="grid">
        <div className="cell">
          <div className="cell_content">
            <Description />
          </div>
        </div>
        <div className="cell">
          <div className="cell_content">
            <div
              className="asd"
              style={{
                padding: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around',
              }}
            >
              <a title="HOME" href="/" style={{ padding: '16px' }}><img src={home} alt="home" /></a>
              <a href="/CHAD_Blocks_instructions_1.pdf" style={{ padding: '16px' }} download><img src={instructions} alt="instructions" /></a>
              <a title="BURN" href="/burn" rel="noreferrer" style={{ padding: '16px' }}><img src={fire} alt="burn" /></a>
            </div>

            <ImageContainer />
            <Wallet />
            {wallet.address ? <Message text={message} /> : <Message text="" />}
          </div>
        </div>
        <div className="cell">
          <div className="cell_content">
            <WalletDetails wallet={wallet} />
            {wallet.address && <p className="text">- - - - - </p>}
            <br />
            <p className="text">MINTED</p>
            <br />
            <p className="text">
              <GetNrMinted />
              {' '}
              /
              {' '}
              {supply?.total || '???'}
            </p>
            <Price price={price} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
