import React from 'react';
import './Footer.css';
import { useLocation } from 'react-router-dom';
import twitter from '../../static/twitter.png';
import discord from '../../static/discord.png';
import opensea from '../../static/opensea.png';
import etherscan from '../../static/etherscan.png';

const isHomepage = (path) => path === '/';

const isPeensPage = (path) => path === '/peens';

export default function Footer() {
  const location = useLocation();
  const path = location.pathname;

  if (isHomepage(path)) {
    return (
      <div className="footer">
        <a className="link" href="https://twitter.com/larvachads" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></a>
        <a className="link" href="https://discord.gg/zYKYawQr6z" target="_blank" rel="noreferrer"><img src={discord} alt="discord" /></a>
        <a className="link" href="https://opensea.io/collection/larva-chads" target="_blank" rel="noreferrer"><img src={opensea} alt="opensea" /></a>
        <a className="link" href="https://etherscan.io/address/0x8fa600364b93c53e0c71c7a33d2ade21f4351da3" target="_blank" rel="noreferrer"><img src={etherscan} alt="etherscan" /></a>
      </div>
    );
  }

  if (isPeensPage(path)) {
    return (
      <div className="footer">
        <a className="link" href="https://twitter.com/larvachads" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></a>
        <a className="link" href="https://discord.gg/zYKYawQr6z" target="_blank" rel="noreferrer"><img src={discord} alt="discord" /></a>
        <a className="link" href="https://opensea.io/collection/vi-peens" target="_blank" rel="noreferrer"><img src={opensea} alt="opensea" /></a>
        <a className="link" href="https://etherscan.io/address/0x6ab712d99a8039103fc91d44c6059430c3d7a3f3" target="_blank" rel="noreferrer"><img src={etherscan} alt="etherscan" /></a>
      </div>
    );
  }

  return (
    <div className="footer">
      <a className="link" href="https://twitter.com/larvachads" target="_blank" rel="noreferrer"><img src={twitter} alt="twitter" /></a>
      <a className="link" href="https://discord.gg/zYKYawQr6z" target="_blank" rel="noreferrer"><img src={discord} alt="discord" /></a>
      <a className="link" href="https://opensea.com/collection/jcpeeney" target="_blank" rel="noreferrer"><img src={opensea} alt="opensea" /></a>
      <a className="link" href="https://etherscan.io/address/0x59c193bd9c3190ee97fd90a5cf46ab0e62e1f7ef" target="_blank" rel="noreferrer"><img src={etherscan} alt="etherscan" /></a>
    </div>
  );
}
