import React from 'react';
import './WalletDetails.css';
import { sliceWalletAddress, roundBalance } from '../../utils';

export default function WalletDetails({ wallet }) {
  const Balance = () => {
    if (wallet.balance || wallet.balance === 0) {
      return `${roundBalance(wallet.balance)} ETH`;
    }
    return '';
  };

  const ChonkyBalance = () => {
    if (wallet.chonkysInBalance || wallet.chonkysInBalance === 0) {
      return `CHONKY POPS BALANCE: ${wallet.chonkysInBalance}`;
    }
  };
  return (
    <div className="wallet-details">
      <div className="detail">
        {wallet.address && `${sliceWalletAddress(wallet.address)}`}
      </div>
      <div className="detail">
        <Balance />
      </div>
      <div className="detail">
        <ChonkyBalance />
      </div>
    </div>

  );
}
