import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorPage from './ErrorPage';
import Store from './routes/Store/Store';
import Home from './routes/Home/Home';
import Peens from './routes/Peens/Peens';
import Burn from './routes/Burn/Burn';
import { StateProvider } from './StateProvider/StateProvider';
import { InitialState, Reducer } from './Reducer/Reducer';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/store',
        element: <Store />,
      },
      {
        path: '/peens',
        element: <Peens />,
      },
      {
        path: '/burn',
        element: <Burn />,
      },
    ],
  },

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StateProvider initialState={InitialState} reducer={Reducer}>
      <RouterProvider router={router} />
    </StateProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
