import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import './Wallet.css';

import ConnectWalletButton from './ConnectWalletButton';
import Message from '../Message/Message';
import WalletDetails from '../WalletDetails/WalletDetails';
import { useStateValue } from '../../StateProvider/StateProvider';
import ChonkyTestAbi from '../../chonky_test_abi.json';
import ChonkyNftJson from '../../ChonkyNft.json';

export default function BurnWallet() {
  const [contractInfo, setContractInfo] = useState({});
  const [walletInfo, setWalletInfo] = useState({});

  const [haveMetamask, sethaveMetamask] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(true);

  const [{ wallet }, dispatch] = useStateValue();

  const DEV_ADDRESS = '0x496490Af09977255E92BB760DB3708A06d68079c';
  const DEV_CONTRACT_ABI = ChonkyTestAbi.abi;

  const PROD_ADDRESS = '0x59C193bd9C3190Ee97Fd90A5Cf46Ab0E62e1f7eF';
  const PROD_ABI = ChonkyNftJson.abi;

  const updateWalletGlobalState = () => {
    dispatch({
      type: 'UPDATE_WALLET',
      wallet: {
        address: walletInfo.address,
        balance: walletInfo.balance,
        chonkysInBalance: walletInfo.chonkysInBalance,
        connected: true,
      },
    });
  };

  const updatePrice = () => {
    dispatch({
      type: 'UPDATE_PRICE',
      price: contractInfo.claimCost,
    });
  };

  const { ethereum } = window;

  useEffect(() => {
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const network = window.ethereum.networkVersion;
      if (network) {
        if (network !== '1') {
          setIsCorrectNetwork(false);
        } else {
          setIsCorrectNetwork(true);
        }
      }
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      updateWalletGlobalState();
      updatePrice();
    }
  }, [isLoading]);

  const initWalletInfo = async (accounts, provider, connectedContract) => {
    const address = accounts[0];
    const balance = await provider.getBalance(address);
    const chonkysInBalance = await connectedContract.balanceOf(address, 0);

    Promise.all([chonkysInBalance]).then(
      setWalletInfo({
        ...walletInfo,
        address,
        balance: Number(ethers.utils.formatEther(balance)),
        chonkysInBalance: chonkysInBalance.toNumber(),
      }),
    );

    if (chonkysInBalance.toNumber() > 0) {
      // enable burn button if > 0
      dispatch({
        type: 'BURN_STATE',
        allowedBurnAmount: chonkysInBalance.toNumber(),
      });
    }
  };

  const initContractInfo = async (connectedContract) => {
    const { state, claimCost } = await connectedContract.items(0);
    setContractInfo({
      ...contractInfo,
      state,
      claimCost: Number(ethers.utils.formatEther(claimCost)),
    });
    dispatch({
      type: 'IS_CLAIM_OPEN',
      isClaimOpen: state === 3,
    });
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });

      const provider = new ethers.providers.Web3Provider(ethereum, 'any');
      const connectedContract = new ethers.Contract(
        PROD_ADDRESS,
        PROD_ABI,
        provider.getSigner(),
      );
      setIsLoading(true);
      await initContractInfo(connectedContract);
      await initWalletInfo(accounts, provider, connectedContract);
      setIsLoading(false);
      setIsConnected(true);
    } catch (error) {
      console.log(error);
      setIsConnected(false);
    }
  };

  const handleAccountsChanged = (...args) => {
    const accounts = args[0];
    if (accounts.length === 0) {
      sethaveMetamask(false);
    } else if (accounts[0] !== walletInfo.address) {
      window.location.reload();
    }
  };

  useEffect(() => {
    ethereum?.on('accountsChanged', handleAccountsChanged);
    return () => {
      updateWalletGlobalState();
      ethereum?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  if (!haveMetamask) {
    return <div style={{ padding: '16px' }}><Message text="PLZ INSTALL METAMASK :P" /></div>;
  }

  if (!isCorrectNetwork) {
    return <div style={{ padding: '16px' }}><Message text="PLZ SWITCH TO ETHEREUM MAINNET :P" /></div>;
  }

  return (
    <div className="wallet">
      { isConnected ? (
        <WalletDetails wallet={wallet} />
      ) : (
        <ConnectWalletButton clickHandler={connectWallet} />
      )}
    </div>
  );
}
