import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import ABI from '../../peens.json';
import CHADS_ABI from '../../chads.json';
import HONORARY from '../../honorary.json';
import './PeensWallet.css';
import ValidationError from '../../exception/ValidationError';
import { fetchProof } from '../../services/FirebaseService';
import { useStateValue } from '../../StateProvider/StateProvider';
import Message from '../../components/Message/Message';

function ConnectWalletButton({ clickHandler }) {
  return (
    <button type="submit" className="connect-wallet-btn" onClick={clickHandler}>
      CONNECT
    </button>
  );
}

const State = {
  CLOSED: 0,
  OPEN: 1,
};

export default function Wallet() {
  const [contractInfo, setContractInfo] = useState({});
  const [walletInfo, setWalletInfo] = useState({});
  const [selectedAmount, setSelectedAmount] = useState(0);

  const [mintButtonDisabled, setMintButtonDisabled] = useState(false);
  const [mintChadButtonDisabled, setMintChadButtonDisabled] = useState(false);

  const [haveMetamask, sethaveMetamask] = useState(true);
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(true);

  const [, dispatch] = useStateValue();

  const updateSupply = () => {
    dispatch({
      type: 'UPDATE_SUPPLY',
      supply: {
        minted: contractInfo.mintedSupply,
        total: contractInfo.supply,
      },
    });
  };

  const updateWalletGlobalState = () => {
    dispatch({
      type: 'UPDATE_WALLET',
      wallet: {
        address: walletInfo.address,
        balance: walletInfo.balance,
      },
    });
  };

  const updatePrice = () => {
    dispatch({
      type: 'UPDATE_PRICE',
      price: contractInfo.price,
    });
  };

  const updateMessage = (displayedMessage) => {
    dispatch({
      type: 'UPDATE_MESSAGE',
      message: displayedMessage,
    });
  };

  const checkButtonDisabled = () => {
    setMintButtonDisabled(false);
    updateMessage('');
    if (contractInfo && contractInfo.state !== State.OPEN) {
      setMintButtonDisabled(true);
      updateMessage('Minting hasn\'t started yet');
      return;
    }

    if (walletInfo.nftsInBalance >= contractInfo.presaleMaxPerWallet) {
      setMintButtonDisabled(true);
      updateMessage('You already have enough PEENS');
    }
  };

  const checkChadMintButtonDisabled = () => {
    setMintChadButtonDisabled(false);
    updateMessage('');
    if (contractInfo && contractInfo.state !== State.OPEN) {
      setMintChadButtonDisabled(true);
      updateMessage('Minting hasn\'t started yet');
      return;
    }

    if (Number(walletInfo.nrChadsInBalance) < 1 && Number(walletInfo.nrHonoraryInBalance) < 1) {
      setMintChadButtonDisabled(true);
      updateMessage('you need to have chads in your wallet to use chad mint :P');
    }

    if (walletInfo.hasClaimedWl) {
      setMintChadButtonDisabled(true);
      updateMessage('you\'ve used your chad mint privileges already :P');
    }

    if (walletInfo.nftsInBalance >= contractInfo.presaleMaxPerWallet) {
      setMintChadButtonDisabled(true);
      updateMessage('You already have enough PEENS');
    }
  };

  const CONTRACT_ADDRESS = '0x6AB712d99A8039103fc91d44c6059430c3d7a3F3';
  const CONTRACT_ABI = ABI.abi;

  const CHADS_CONTRACT_ADDRESS = '0x8FA600364B93C53e0c71C7A33d2adE21f4351da3';
  const CHADS_CONTRACT_ABI = CHADS_ABI.abi;

  const HONORARAY_CONTRACT_ADDRESS = '0xc90F8443E89201F714C78B40352b790a39BaD203';
  const HONORARY_CONTRACT_ABI = HONORARY.abi;

  const { ethereum } = window;

  useEffect(() => {
    const checkMetamaskAvailability = async () => {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      sethaveMetamask(true);
    };
    checkMetamaskAvailability();
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      const network = window.ethereum.networkVersion;
      if (network) {
        if (network !== '1') { // 1 == ethereum mainnet
          setIsCorrectNetwork(false);
        } else {
          setIsCorrectNetwork(true);
        }
      }
      window.ethereum.on('chainChanged', () => {
        window.location.reload();
      });
    }
  });

  useEffect(() => {
    if (!isLoading) {
      updateSupply();
      updateWalletGlobalState();
      updatePrice();
      checkButtonDisabled();
      checkChadMintButtonDisabled();
    }
  }, [isLoading]);

  const initWalletInfo = async (accounts, provider, connectedContract) => {
    const address = accounts[0];
    const balance = await provider.getBalance(address);
    const nftsInBalance = await connectedContract.balanceOf(address);
    const hasClaimedWl = await connectedContract.getChadMintClaimed(address);
    const proof = await fetchProof(address);

    const chadsContract = new ethers.Contract(
      CHADS_CONTRACT_ADDRESS,
      CHADS_CONTRACT_ABI,
      provider.getSigner(),
    );

    const chadsBalance = await chadsContract.balanceOf(address);

    const honoraryContract = new ethers.Contract(
      HONORARAY_CONTRACT_ADDRESS,
      HONORARY_CONTRACT_ABI,
      provider.getSigner(),
    );
    const honoraryBalance = await honoraryContract.balanceOf(address);

    Promise.all([balance, nftsInBalance, hasClaimedWl, proof, chadsBalance, honoraryBalance]).then(
      setWalletInfo({
        ...walletInfo,
        address,
        balance: Number(ethers.utils.formatEther(balance)),
        nftsInBalance: nftsInBalance.toNumber(),
        hasClaimedWl,
        nrChadsInBalance: chadsBalance.toNumber(),
        nrHonoraryInBalance: honoraryBalance.toNumber(),
      }),
    );
  };

  const initContractInfo = async (connectedContract) => {
    const mintedSupply = await connectedContract.totalMinted();
    const supply = await connectedContract.supply();
    const maxPerWallet = await connectedContract.maxPerWallet();
    const maxPerTransaction = await connectedContract.maxPerTransaction();
    const price = await connectedContract.price();
    const state = await connectedContract.saleState();
    const promises = [mintedSupply, supply, maxPerWallet, maxPerTransaction, price, state];

    Promise.all(promises).then(() => {
      setContractInfo({
        ...contractInfo,
        mintedSupply: mintedSupply.toNumber(),
        supply: supply.toNumber(),
        maxPerWallet: maxPerWallet.toNumber(),
        maxPerTransaction: maxPerTransaction.toNumber(),
        price: Number(ethers.utils.formatEther(price)),
        state,
      });
    });
  };

  const connectWallet = async () => {
    try {
      if (!ethereum) {
        sethaveMetamask(false);
      }
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const connectedContract = new ethers.Contract(
        CONTRACT_ADDRESS,
        CONTRACT_ABI,
        signer,
      );
      setIsLoading(true);
      await initContractInfo(connectedContract);
      await initWalletInfo(accounts, provider, connectedContract);
      setIsLoading(false);
      setIsConnected(true);
    } catch (error) {
      console.log(error);
      setIsConnected(false);
      updateMessage('Something went horribly wrong');
    }
  };

  const handleAccountsChanged = (...args) => {
    const accounts = args[0];
    if (accounts.length === 0) {
      sethaveMetamask(false);
    } else if (accounts[0] !== walletInfo.address) {
      window.location.reload();
    }
  };

  useEffect(() => {
    ethereum?.on('accountsChanged', handleAccountsChanged);
    return () => {
      updateWalletGlobalState();
      ethereum?.removeListener('accountsChanged', handleAccountsChanged);
    };
  }, []);

  const getAllowedAmount = (isChadMint) => {
    if (isChadMint) {
      const allowedFromChads = walletInfo.nrChadsInBalance * 2;
      const allowedFromHonorary = walletInfo.nrHonoraryInBalance * 20;
      return allowedFromChads + allowedFromHonorary;
    }

    return contractInfo.maxPerTransaction;
  };

  const validate = (isChadMint) => {
    if (selectedAmount < 1) {
      throw new ValidationError('WTF IS THIS NUMBER');
    }

    const allowedAmount = getAllowedAmount(isChadMint);
    if (selectedAmount > allowedAmount) {
      throw new ValidationError(`You can only mint ${allowedAmount} PEENS per tx with your selected mint type`);
    }

    if (
      Number(walletInfo.nftsInBalance) + Number(selectedAmount)
      > contractInfo.maxPerWallet
    ) {
      throw new ValidationError('You already have too much PEENS');
    }

    if (selectedAmount > contractInfo.presaleSupply - contractInfo.mintedSupply) {
      throw new ValidationError('There isn\'t that many left :/');
    }
  };

  const mint = async (isChadMint) => {
    try {
      if (ethereum) {
        validate(isChadMint);

        const provider = new ethers.providers.Web3Provider(ethereum, 'any');
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          CONTRACT_ABI,
          signer,
        );
        updateMessage('Confirm tx in Metamask plz');

        const totalPrice = isChadMint ? '0' : (Number(contractInfo.price) * Number(selectedAmount)).toString();
        const valueInEth = ethers.utils.parseEther(totalPrice);

        if (isChadMint) {
          const gasEstimate = await connectedContract.estimateGas.chadMint(
            selectedAmount,
            { value: valueInEth },
          );
          const txn = await connectedContract.chadMint(
            selectedAmount,
            { gasLimit: gasEstimate.toNumber() + 10000, value: valueInEth },
          );
          updateMessage('Waiting for the tx to complete...');
          await txn.wait();
          updateMessage('Enjoy your PEENS');
        } else {
          const gasEstimate = await connectedContract.estimateGas.mint(
            selectedAmount,
            { value: valueInEth },
          );

          const txn = await connectedContract.mint(
            selectedAmount,
            { gasLimit: gasEstimate.toNumber() + 10000, value: valueInEth },
          );
          updateMessage('Waiting for the tx to complete...');
          await txn.wait();
          updateMessage('Enjoy your PEENS');
        }

        connectWallet(); // to reset state
      } else {
        console.log('Ethereum object does not exist');
      }
    } catch (error) {
      if (error instanceof ValidationError) {
        updateMessage(error.message);
      } else {
        if (error.code === 'ACTION_REJECTED') {
          updateMessage('you rejected? :(');
        } else {
          updateMessage('Something went wrong... :/');
        }

        console.log(error);
      }
    }
  };

  const increment = () => {
    setSelectedAmount(selectedAmount + 1);
  };

  const decrement = () => {
    if (selectedAmount >= 1) {
      setSelectedAmount(selectedAmount - 1);
    }
  };

  if (!haveMetamask) {
    return <div style={{ padding: '16px' }}><Message text="PLZ INSTALL METAMASK :P" /></div>;
  }

  if (!isCorrectNetwork) {
    return <div style={{ padding: '16px' }}><Message text="PLZ SWITCH TO ETHEREUM MAINNET :P" /></div>;
  }

  return (
    <div className="wallet">
      {isConnected ? (
        <div className="minting">
          <div className="quantity">
            <button className="eightbit-btn" onClick={decrement}>-</button>
            <button className="amount-btn">{selectedAmount}</button>
            <button className="eightbit-btn" onClick={increment}>+</button>
          </div>
          <button type="submit" className="eightbit-btn" onClick={() => { mint(false); }} disabled={mintButtonDisabled}>MintPublic</button>
          <button type="submit" className="eightbit-btn" onClick={() => { mint(true); }} disabled={mintChadButtonDisabled}>MintChad</button>
        </div>
      ) : (
        <ConnectWalletButton clickHandler={connectWallet} />
      )}
    </div>
  );
}
