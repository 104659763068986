/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { getDatabase, ref, set } from 'firebase/database';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';

import { countries } from '../../countries';
import './Form.css';
import { useStateValue } from '../../StateProvider/StateProvider';
import ChonkyTestAbi from '../../chonky_test_abi.json';
import ChonkyNftJson from '../../ChonkyNft.json';

const CountriesList = () => countries.map((country) => <option value={country}>{country}</option>);

export default function Form() {
  const {
    register, formState: { errors }, handleSubmit,
  } = useForm();
  const [burnAmount, setBurnAmount] = useState(0);
  const [{
    wallet, allowedBurnAmount, price, isClaimOpen,
  }, dispatch] = useStateValue();
  const { ethereum } = window;

  const DEV_ADDRESS = '0x496490Af09977255E92BB760DB3708A06d68079c';
  const DEV_ABI = ChonkyTestAbi.abi;

  const PROD_ADDRESS = '0x59C193bd9C3190Ee97Fd90A5Cf46Ab0E62e1f7eF';
  const PROD_ABI = ChonkyNftJson.abi;

  const writeData = (data, location) => {
    const db = getDatabase();
    set(ref(db, `tests/${location}`), data);
  };

  const saveBurnData = (data) => {
    const saveData = {
      ...data,
      wallet: wallet.address,
      amount: burnAmount,
    };
    writeData(saveData, wallet.address);
  };

  const updateMessage = (message) => {
    dispatch({
      type: 'BURN_MESSAGE',
      burnStatusMessage: message,
    });
  };

  useEffect(() => {
    if (!isClaimOpen && wallet.connected) {
      updateMessage('Burn has not started yet');
    }
  }, [isClaimOpen]);

  const burn = async (data) => {
    try {
      updateMessage('');
      if (ethereum) {
        if (burnAmount < 1) {
          updateMessage('WTF IS THIS AMOUNT');
          return;
        }
        const provider = new ethers.providers.Web3Provider(ethereum, 'any');
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          PROD_ADDRESS,
          PROD_ABI,
          signer,
        );
        updateMessage('Confirm tx in Metamask plz');
        const finalPrice = price * burnAmount;

        const gasEstimate = await connectedContract.estimateGas.burnToClaim(
          0,
          burnAmount.toString(),
          { value: ethers.utils.parseEther(finalPrice.toString()) },
        );

        const txn = await connectedContract.burnToClaim(
          0,
          burnAmount,
          {
            gasLimit: gasEstimate.toNumber() + 10000,
            value: ethers.utils.parseEther(finalPrice.toString()),
          },
        );
        updateMessage('Waiting for the tx to complete...');
        await txn.wait();
        saveBurnData(data);
        updateMessage('Success! Chonky pops will be with you shortly');
        dispatch({
          type: 'BURN_STATE',
          allowedBurnAmount: 0,
        });
      } else {
        console.log('Ethereum object does not exist');
      }
    } catch (error) {
      if (error.code === 'ACTION_REJECTED') {
        updateMessage('you rejected? :(');
      } else {
        updateMessage('Something went wrong... :/');
      }
      console.log(error);
    }
  };

  const increment = () => {
    if (burnAmount + 1 <= allowedBurnAmount) {
      setBurnAmount(burnAmount + 1);
    } else {
      updateMessage(`You can't burn more than ${allowedBurnAmount}`);
    }
  };

  const decrement = () => {
    if (burnAmount >= 1) {
      setBurnAmount(burnAmount - 1);
      updateMessage('');
    }
  };

  return (
    <div className="form">
      <form onSubmit={handleSubmit(burn)}>

        <p>FULL NAME</p>
        <input {...register('fullName', { required: true })} className="flex" aria-invalid={errors.fullName ? 'true' : 'false'} />
        {errors.fullName?.type === 'required' && <p className="required">Full name is required</p>}

        <p>EMAIL</p>
        <input {...register('email', { required: true })} className="flex" />
        {errors.email?.type === 'required' && <p className="required">Email is required</p>}

        <p>COUNTRY</p>
        <select {...register('country', { required: true })} className="flex">
          <CountriesList />
        </select>
        {errors.country?.type === 'required' && <p className="required">Country is required</p>}

        <p>CITY</p>
        <input {...register('city', { required: true })} className="flex" />
        {errors.city?.type === 'required' && <p className="required">City is required</p>}

        <p>STATE / PROVINCE / REGION</p>
        <input {...register('state', { required: true })} className="flex" />
        {errors.state?.type === 'required' && <p className="required">State / province / region is required</p>}

        <p>STREET ADDRESS</p>
        <input {...register('street', { required: true })} className="flex" />
        {errors.street?.type === 'required' && <p className="required">Street is required</p>}

        <p>ZIPCODE</p>
        <input {...register('zipCode', { required: true })} className="flex" />
        {errors.zipCode?.type === 'required' && <p className="required">Zipcode is required</p>}

        <p>PHONE NUMBER WITH COUNTRY CODE (+)</p>
        <input {...register('phoneNumber', { required: true })} className="flex" />
        {errors.phoneNumber?.type === 'required' && <p className="required">Phone number is required</p>}
        <div className="leburn">
          <div className="quantity">
            <button type="button" className="eightbit-btn" style={{ marginTop: '16px' }} onClick={decrement} disabled={allowedBurnAmount === 0 || !isClaimOpen}>-</button>
            <button type="button" className="amount-btn" style={{ marginTop: '16px' }} disabled={allowedBurnAmount === 0 || !isClaimOpen}>{burnAmount}</button>
            <button type="button" className="eightbit-btn" style={{ marginTop: '16px' }} onClick={increment} disabled={allowedBurnAmount === 0 || !isClaimOpen}>+</button>
          </div>
          <button disabled={allowedBurnAmount === 0 || !isClaimOpen} type="submit" className="eightbit-btn" style={{ marginTop: '16px' }}>BURN</button>
        </div>

      </form>
    </div>
  );
}
