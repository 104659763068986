import './App.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { inject } from '@vercel/analytics';
import firebaseConfig from './firebaseConfig';

function App() {
  initializeApp(firebaseConfig);
  inject();
  return (
    <div className="app">
      {/* <NavBar /> */}
      <Outlet />
    </div>
  );
}

export default App;
