import {
  getDatabase, get, ref, child,
} from 'firebase/database';

const fetchProof = async (walletAddress) => {
  const dbRef = ref(getDatabase());
  // todo make sure all of the wallet addresses are in lowercase in the DB!
  return get(child(dbRef, `proofs/${walletAddress}`));
};

const fetchAll = async () => {
  const dbRef = ref(getDatabase());
  return get(child(dbRef, 'proofs'));
};

export { fetchProof, fetchAll };
