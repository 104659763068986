import React from 'react';
import Form from '../../components/Form/Form';
import BurnWallet from '../../components/Wallet/BurnWallet';
import Message from '../../components/Message/Message';
import Price from '../../components/Price/Price';
import { useStateValue } from '../../StateProvider/StateProvider';
import irl from '../../static/chonky_pops_irl.png';
import home from '../../static/home.png';

import './Burn.css';

export default function Burn() {
  const [{ burnStatusMessage, price }] = useStateValue();
  return (
    <div className="container">
      <div className="grid">
        <div className="cell">
          <div className="cell_content" style={{ lineHeight: '1.5rem' }}>
            "Burn your Chonky Pops NFT to claim IRL version of it, which will be shipped to your desired location world wide. Note that there is a small burn fee to cover shipping costs.
            <br />
            <br />
            <div style={{ fontSize: '10px' }}>
              Disclaimer:
              {' '}
              <br />
              {' '}
              Once we’ve dispatched Chonky Pops, we cannot be responsible for any issues that may arise with customs. Also, from a legal POV, our lawyer said to say - ‘these are non FDA approved and a parody product for display purposes only’."
            </div>

          </div>
        </div>

        <div className="cell">
          <div className="cell_content">
            <a title="HOME" href="/" style={{ justifyContent: 'center', display: 'flex', padding: '16px' }}><img src={home} alt="home" /></a>
            <img className="irl" alt="chonky-boi" src={irl} />
            <BurnWallet />
          </div>
        </div>
        <div className="cell">
          <div className="cell_content">
            <Form />
            <Price price={price} />
            <Message text={burnStatusMessage} />
          </div>
        </div>
      </div>
    </div>
  );
}
