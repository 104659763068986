import React from 'react';
import './Description.css';

export default function Description() {
  return (
    <p className="cereal_description">
      Chonky Pops are an IRL product created by Larva Chads. This is our first IRL meme, and was created to reward Chad Holders.
      Chad’s ‘Chonky Pops’ are a bacon flavoured cereal. This meaty milky goodness  gives holdrs  the ultimate GM.
      <br />
      <br />
      We’ve created 100 Ltd Edition physical boxes - 10 random boxes with surprise toys inside! There are 3 ways for holders to claim a box. Those selected will access these through a private allowlist - where they can mint a digital twin NFT of the physical box.
    </p>
  );
}
