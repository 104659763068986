import React from 'react';
import Footer from '../../components/Footer/Footer';
import boi from '../../static/gif.gif';
import './Home.css';

export default function Home() {
  return (
    <div className="container">
      <div className="grid">
        <div className="cell">
          <div className="cell_content">
          </div>
        </div>
        <div className="cell">
          <div className="cell_content" style={{ display: 'flex', flexDirection: 'column' }}>
            <img style={{ padding: '16px', display: 'flex' }} src={boi} alt="spinny boi" />
          </div>
        </div>
        <div className="cell">
          <div className="cell_content" style={{ display: 'flex', flexDirection: 'column' }}>
            <a href="/store" rel="noreferrer">
              <button type="submit" className="eightbit-btn">JCPEENEY STORE</button>
            </a>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}
