import React from 'react';
import './Peens.css';
import PeensWallet from './PeensWallet';
import WalletDetails from '../../components/WalletDetails/WalletDetails';
import Message from '../../components/Message/Message';
import Price from '../../components/Price/Price';
import { useStateValue } from '../../StateProvider/StateProvider';
import Footer from '../../components/Footer/Footer';
import home from '../../static/home.png';
import peens from '../../static/peens.gif';

export default function Store() {
  const [{
    supply, wallet, price, message,
  }] = useStateValue();

  const GetNrMinted = () => {
    if (supply.minted) {
      return supply.minted;
    }
    if (supply.minted === 0) {
      return 0;
    }
    return '???';
  };
  return (
    <div className="container">
      <div className="grid">
        <div className="cell">
          <div className="cell_content">
            <p className="cereal_description">
              'Peens - VI Edition' is a play on 'Checks - VV Edition'
              <br />
              <br />
              This artwork is not notable.
              <br />
              <br />
              Rules:
              <br />
              CHADMINT - 2 for every chad you own, 20 for every honorary chad
              <br />
              <br />
              PUBLIC - go crazyyyyyy (max 100 p/tx, 500 p/w)
            </p>
          </div>
        </div>
        <div className="cell">
          <div className="cell_content">
            <a href="/" style={{ justifyContent: 'center', display: 'flex', padding: '16px' }}><img src={home} alt="home" /></a>
            <img className="NFT" alt="chonky-boi" src={peens} />
            <PeensWallet />
            {wallet.address ? <Message text={message} /> : <Message text="" />}
          </div>
        </div>
        <div className="cell">
          <div className="cell_content">
            <WalletDetails wallet={wallet} />
            {wallet.address && <p className="text">- - - - - </p>}
            <br />
            <p className="text">MINTED</p>
            <br />
            <p className="text">
              <GetNrMinted />
              {' '}
              /
              {' '}
              {supply?.total || '???'}
            </p>
            <Price price={price} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
